import { defaultRoutePath } from '@/constants/const';
import { AuthKey } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { LockOutlined } from '@ant-design/icons';
import { Result } from '@antd';
import { useCreation } from 'ahooks';
import { useNavigate } from 'umi';
import Container from '../PageCard';
import { useEffect } from 'react';
import { dict } from '@/hooks/useChangeLocale';

/**
 * 权限组件
 * @param allowedKey
 * @returns
 */
export const withPermission = (allowedKey: AuthKey | AuthKey[]) => (WrappedComponent) => {
  return (props) => {
    const { hasPermission } = useAppContext();
    const authorized = useCreation(() => hasPermission(allowedKey), [allowedKey]);
    const navigate = useNavigate();

    useEffect(() => {
      if (!authorized) {
        navigate(`/${defaultRoutePath}`);
      }
    }, [authorized]);

    return authorized ? (
      <WrappedComponent {...props} />
    ) : (
      <Container>
        <Result
          status="warning"
          title={dict('NO_PERMISSION_VISIT')}
          icon={<LockOutlined />}
          subTitle={dict('PLEASE_CONTACT_ADMINISTRATOR_TO_OBTAIN_PERMISSION')}
        />
      </Container>
    );
  };
};
