import { DateUtils } from '@/utils/dateUtil';
import { Format } from '@/utils/format';
import { Typography } from '@antd';
import { isArray } from 'lodash';
import React, { CSSProperties } from 'react';

type TextProps = {
  value?: Key;
  style?: CSSProperties | undefined;
};

const { Text: AntdText } = Typography;

export const Text: React.FC<TextProps> = ({ value, style }) => {
  const showValue = isArray(value) ? value.join('，') : value;
  return <AntdText style={{ ...style }}> {showValue || '-'}</AntdText>;
};

type ListTextProps = { list: AnyObject[]; key?: Key; label?: string } & TextProps;
export const ArrLabelText: React.FC<ListTextProps> = ({ value, list, key = 'value', label = 'label', ...rest }) => {
  return <Text value={Format.toArrLabel(list, value as Key, key, label)} {...rest} />;
};

export const DateTimeText: React.FC<TextProps> = ({ value, ...props }) => {
  return <Text value={value ? DateUtils.formatDateTime(value) : ''} {...props} />;
};

export default Text;
