import { ArrowLeft } from '@/icons';
import { useNavigate } from 'umi';
import style from './index.less';

export type GoBackPropsType = {
  path?: string;
  name: string;
  back?: VoidFunction | false;
};

export default function (props: GoBackPropsType) {
  const { name, path, back } = props;
  const navigateTo = useNavigate();
  return (
    <div className={style['go-back-sub-nav']}>
      {back !== false && (
        <div className={style['go-back-btn']} onClick={back ? back : () => (path ? navigateTo(path) : history.back())}>
          <ArrowLeft />
        </div>
      )}
      {name}
    </div>
  );
}
