import { themeConfig } from '@/layouts/const/themes';
import Color from 'color';

export const chortColors = [Color(themeConfig.colorPrimary).alpha(0.95).lighten(0.2).hex()];
const DEFAULT_THEME = {
  color: [
    Color(themeConfig.colorPrimary).alpha(0.95).lighten(0.2).hex(),
    '#FFBE3D',
    '#2DCCA6',
    '#B257D9',
    '#9C82E1',
    '#8795B2',
    '#006633',
  ],
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#E2E6E9',
      },
    },
    axisLabel: {
      color: '#67717A',
      margin: 0,
    },
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: 'red',
      },
    },
    // splitArea: { show: false },
    axisTick: { show: false },
  },
  valueAxis: {
    axisLine: { show: false },
    splitLine: {
      lineStyle: {
        type: 'dashed',
      },
    },
    minInterval: 1,
  },
  legend: {
    show: true,
    icon: 'circle',
    orient: 'horizontal', // 图例排布方向：vertical | horizontal
    itemWidth: 10, // 设置宽度
    itemHeight: 10, // 设置高度
    right: 0, // 距离容器左侧距离，可为百分比，也可以是 left、center、right
    itemGap: 14,
    textStyle: {
      color: '#67717A',
      fontSize: 12,
    },
  },
  tooltip: {
    borderWidth: 0,
  },
  line: {
    areaStyle: {
      opacity: 0.2,
    },
    symbol: 'circle',
    // smooth: true,
  },
  grid: {
    containLabel: true,
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
  },
};

export default DEFAULT_THEME;
