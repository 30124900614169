import { dict } from '@/hooks/useChangeLocale';
import { DateUtils } from '@/utils/dateUtil';
import { DatePicker } from '@antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const { RangePicker } = DatePicker;

const days = 31;
const today = dayjs(DateUtils.format(dayjs()));
const yesterday = today.add(-1, 'd');
type RangePresetType = {
  label: string;
  value: [Dayjs, Dayjs];
};
type RangeValue = [Dayjs | null, Dayjs | null] | null;
type DateRangePickerProps = {
  endDay?: 'today' | 'yesterday';
} & RangePickerProps;
const getRangePresets = (endDay: Dayjs) => {
  return [
    { label: dict('YESTERDAY'), value: [yesterday, endDay] },
    { label: dict('LAST_NUM_DAYS', { num: 3 }), value: [today.add(-3, 'd'), endDay] },
    { label: dict('LAST_NUM_DAYS', { num: 7 }), value: [today.add(-7, 'd'), endDay] },
    { label: dict('LAST_NUM_DAYS', { num: 15 }), value: [today.add(-15, 'd'), endDay] },
    { label: dict('LAST_NUM_DAYS', { num: 30 }), value: [today.add(-31, 'd'), endDay] },
  ] as RangePresetType[];
};

export const rangePresets: RangePresetType[] = getRangePresets(yesterday);
export const rangePresetsToToday: RangePresetType[] = getRangePresets(today);

const DateRangePicker: React.FC<DateRangePickerProps> = ({ endDay = 'yesterday', value, ...props }) => {
  const [dates, setDates] = useState<RangeValue>(null);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= days;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= days;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <RangePicker
      presets={endDay == 'yesterday' ? rangePresets : rangePresetsToToday}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onOpenChange={onOpenChange}
      value={dates || value}
      {...props}
    />
  );
};

export default DateRangePicker;
