import { useAppContext } from '@/contexts/AppContext';
import { Format } from '@/utils/format';
import { CSSProperties } from 'react';

type EnumTextProps = {
  type: string;
  value?: unknown;
  valueKey?: 'value' | 'name';
  style?: CSSProperties | undefined;
};

export const EnumText = ({ type, value, valueKey = 'value', style }: EnumTextProps) => {
  const { getEnum } = useAppContext();
  return <span style={style}> {Format.toArrLabel(getEnum(type), value as Key, valueKey, 'displayName')}</span>;
};

export default EnumText;
