import { isArray } from 'lodash';
import React from 'react';
import Highlight from 'react-highlight-words';
import style from './index.less';

interface Props {
  keywordText: string | string[];
  text: string;
  searchText?: string;
}

/**
 * 高亮显示文本
 * @returns
 */
const HighlightText: React.FC<Props> = ({ keywordText, text, searchText }) => {
  const keywordHighLightColor = '#2DCCA6';

  const hightLightSearchWords = (text, isKeyword) => {
    let style = 'background-color: yellow';
    if (isKeyword) style += `;color:${keywordHighLightColor}`;
    return searchText ? text.replace(searchText, `<mark style="${style}">${searchText}</mark>`) : text;
  };
  return (
    <Highlight
      className={style['higiht-light-wrap']}
      searchWords={isArray(keywordText) ? [...keywordText] : [keywordText]}
      autoEscape={false}
      textToHighlight={text}
      unhighlightTag={({ children }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<span style="color:inherit">${hightLightSearchWords(children, false)}</span>`,
          }}
        />
      )}
      highlightTag={({ children }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<span style="color:${keywordHighLightColor};backgroundColor: transparent">${hightLightSearchWords(
              children,
              true,
            )}</span>`,
          }}
        />
      )}
    />
  );
};

export default HighlightText;
