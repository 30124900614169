import { useAppContext } from '@/contexts/AppContext';
import { getTagList } from '@/services/tag/indext';
import { useRequest } from 'ahooks';
import { Flex, Tag } from '@antd';
import { Function1 } from 'lodash';

//标签选择框
type TagCheckTagBoxProps = {
  value?: number[];
  onChange?: Function1<number[], void>;
};
const TagCheckTagBox = ({ value, onChange }: TagCheckTagBoxProps) => {
  const { user } = useAppContext();
  const checkValue = value || [];

  const { data } = useRequest(
    async () => {
      const list = await getTagList({ bid: user?.bid });
      const tagValOptions: { value: number; label: string }[] = [];
      list.forEach((v) =>
        v.ruleConfig.forEach((t) => tagValOptions.push({ label: t.valueName, value: t.tagValueId as number })),
      );
      return tagValOptions;
    },
    { refreshDeps: [user?.bid] },
  );

  return (
    <Flex wrap="wrap" gap={10}>
      {data?.map((v) => (
        <Tag.CheckableTag
          key={v.value}
          checked={checkValue.includes(v.value)}
          onChange={(checked) =>
            onChange?.(checked ? [...checkValue, v.value] : checkValue?.filter((item) => item != v.value))
          }
        >
          {v.label}
        </Tag.CheckableTag>
      ))}
    </Flex>
  );
};

export default TagCheckTagBox;
