import { Tooltip as AntTooltip, TooltipProps } from '@antd/Antd';

export const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <AntTooltip placement="rightTop" {...props}>
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
