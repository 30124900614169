const douyinEmojiNameMapping = {
  过年牛: 'guonianniu',
  元宝: 'yuanbao',
  鞭炮: 'bianpao',
  汤圆: 'tangyuan',
  戴口罩: 'daikouzhao',
  勤洗手: 'qinxishou',
  不信谣言: 'buxinyaoyan',
  情书: 'qingshu',
  微笑: 'weixiao',
  色: 'aimu',
  爱慕: 'aimu',
  发呆: 'liangdai',
  惊呆: 'liangdai',
  酷拽: 'kuye',
  抠鼻: 'koubi',
  流泪: 'liulei',
  捂脸: 'wulian',
  发怒: 'fanu',
  呲牙: 'ziya',
  一起加油: 'yiqijiayou',
  睡: 'hanshui',
  鼾睡: 'hanshui',
  害羞: 'haixiu',
  调皮: 'keai',
  可爱: 'keai',
  晕: 'yun',
  衰: 'shuai',
  闭嘴: 'bizui',
  机智: 'jizhi',
  赞: 'zan',
  鼓掌: 'guzhang',
  感谢: 'qidao',
  祈祷: 'qidao',
  来看我: 'laikanwo',
  灵机一动: 'lingguangyishan',
  灵光一闪: 'lingguangyishan',
  耶: 'ye',
  打脸: 'dalian',
  大笑: 'daxiao',
  哈欠: 'haqian',
  震惊: 'zhenliang',
  送心: 'songxin',
  困: 'kun',
  疑问: 'what',
  what: 'what',
  泣不成声: 'qibuchengsheng',
  小鼓掌: 'xiaoguzhang',
  大金牙: 'dajinya',
  偷笑: 'touxiao',
  石化: 'shihua',
  思考: 'sikao',
  吐血: 'tuxie',
  可怜: 'kelian',
  嘘: 'xu',
  撇嘴: 'piezui',
  尴尬: 'heixian',
  黑线: 'heixian',
  笑哭: 'xiaoku',
  生病: 'wumai',
  雾霾: 'wumai',
  奸笑: 'jianxiao',
  得意: 'deyi',
  憨笑: 'hanxiao',
  坏笑: 'huaixiao',
  抓狂: 'zhuakuang',
  泪奔: 'leiben',
  钱: 'qian',
  亲亲: 'wen',
  吻: 'wen',
  恐惧: 'kongju',
  愉快: 'xiao',
  笑: 'xiao',
  快哭了: 'kuaikule',
  翻白眼: 'fanbaiyan',
  傲慢: 'fanbaiyan',
  互粉: 'hufen',
  我想静静: 'woxiangjingjing',
  委屈: 'weiqu',
  舔屏: 'tianping',
  鄙视: 'bishi',
  飞吻: 'feiwen',
  再见: 'zaijian',
  紫薇别走: 'ziweibiezou',
  听歌: 'tingge',
  拥抱: 'qiubaobao',
  求抱抱: 'qiubaobao',
  绝望的凝视: 'zhoudongyudeningshi',
  不失礼貌的微笑: 'masichundeweixiao',
  吐舌: 'tushe',
  呆无辜: 'daiwugu',
  看: 'kan',
  白眼: 'baiyan',
  熊吉: 'xiongji',
  猪头: 'zhutou',
  不看: 'bukan',
  冷漠: 'lengmo',
  微笑袋鼠: 'weixiaodaishu',
  凝视: 'ningshi',
  暗中观察: 'anzhongguancha',
  骷髅: 'kulou',
  黑脸: 'heilian',
  吃瓜群众: 'chiguaqunzhong',
  吃瓜: 'chiguaqunzhong',
  绿帽子: 'lvmaozi',
  流汗: 'han',
  汗: 'han',
  摸头: 'motou',
  皱眉: 'zhoumei',
  擦汗: 'cahan',
  红脸: 'honglian',
  尬笑: 'gaxiao',
  做鬼脸: 'zuoguilian',
  强: 'qiang',
  如花: 'ruhua',
  吐: 'tu',
  惊喜: 'liangxi',
  敲打: 'qiaoda',
  奋斗: 'fendou',
  吐彩虹: 'tucaihong',
  大哭: 'daku',
  嘿哈: 'heiha',
  加好友: 'jiahaoyou',
  惊恐: 'jingkong',
  惊讶: 'jingya',
  囧: 'jiong',
  难过: 'nanguo',
  斜眼: 'xieyan',
  阴险: 'yinxian',
  左哼哼: 'zuohengheng',
  右哼哼: 'youhengheng',
  悠闲: 'youxian',
  咒骂: 'zhouma',
  比心: 'bixin',
  强壮: 'jiayou',
  加油: 'jiayou',
  碰拳: 'pengquan',
  OK: 'ok',
  ok: 'ok',
  击掌: 'jizhang',
  左上: 'zuoshang',
  握手: 'woshou',
  抱拳: 'baoquan',
  勾引: 'gouyin',
  拳头: 'quantou',
  弱: 'ruo',
  胜利: 'shengli',
  右边: 'youbian',
  左边: 'zuobian',
  嘴唇: 'kiss',
  kiss: 'kiss',
  去污粉: 'quwufen',
  '666': '666',
  玫瑰: 'meigui',
  胡瓜: 'hugua',
  啤酒: 'pijiu',
  '18禁': '18jin',
  我太难了: 'wotainanle',
  菜刀: 'dao',
  刀: 'dao',
  V5: 'V5',
  给力: 'geili',
  爱心: 'xin',
  心: 'xin',
  心碎: 'shangxin',
  伤心: 'shangxin',
  便便: 'shi',
  屎: 'shi',
  加鸡腿: 'jiajitui',
  蛋糕: 'dangao',
  气球: 'qiqiu',
  炸弹: 'zhadan',
  发: 'fa',
  派对: 'sahua',
  撒花: 'sahua',
  礼物: 'liwu',
  红包: 'hongbao',
  咖啡: 'kafei',
  凋谢: 'diaoxie',
  西瓜: 'xigua',
  太阳: 'taiyang',
  月亮: 'yueliang',
  锦鲤: 'jinli',
  过年鼠: 'guonianshu',
  圣诞树: 'shengdanshu',
  圣诞帽: 'shengdanmao',
  雪花: 'xuehua',
  平安果: 'pinganguo',
  握爪: 'wozhua',
  我酸了: 'wosuanle',
  小黄鸭: 'xiaohuangya',
  棒棒糖: 'bangbangtang',
  纸飞机: 'zhifeiji',
  必胜: 'bisheng',
  粽子: 'zongzi',
  蕉绿: 'jiaolv',
  绝: 'jue',
  给跪了: 'geiguile',
  扎心: 'zhaxin',
  干杯: 'ganbei',
  烟花: 'yanhua',
  小红花: 'xiaohonghua',
  饺子: 'jiaozi',
  福: 'fu',
  灯笼: 'denglong',
  巧克力: 'qiaokeli',
  戒指: 'jiezhi',
  罗三炮: 'luosanpao',
};

const xiaohongshuEmojiNameMapping = [
  'doge',
  'kissR',
  'okR',
  'ootdR',
  '一R',
  '七R',
  '三R',
  '两颗心R',
  '中浅肤色R',
  '中深肤色R',
  '中等肤色R',
  '举手R',
  '九R',
  '买爆R',
  '二R',
  '五R',
  '仙女R',
  '优惠券R',
  '偷笑R',
  '八R',
  '公路车R',
  '六R',
  '再见R',
  '冲浪板R',
  '凋谢R',
  '刀R',
  '加一R',
  '加油R',
  '勾引R',
  '卡式炉R',
  '双翘滑板R',
  '发R',
  '可R',
  '可怜R',
  '叹气R',
  '吃瓜R',
  '合十R',
  '同款R',
  '吐舌头H',
  '向右R',
  '吧唧R',
  '咖啡R',
  '哇R',
  '哭惹R',
  '啤酒R',
  '喝奶茶R',
  '四R',
  '大便R',
  '大笑R',
  '天幕R',
  '太阳R',
  '失望R',
  '头盔R',
  '学生党R',
  '害羞R',
  '庆祝R',
  '开箱R',
  '弱R',
  '彩虹R',
  '得意R',
  '微笑R',
  '惊恐R',
  '手套R',
  '打卡R',
  '扯脸H',
  '扶墙R',
  '抓狂R',
  '折叠椅R',
  '折叠车R',
  '抠鼻R',
  '抱拳R',
  '拍立得R',
  '拔草R',
  '拥抱R',
  '拳头R',
  '捂脸R',
  '探店R',
  '握手R',
  '放大镜R',
  '斜眼R',
  '新月R',
  '星R',
  '晚安R',
  '暗中观察R',
  '有R',
  '栗子R',
  '桃子R',
  '樱花R',
  '气球R',
  '氛围感R',
  '汗颜R',
  '派对R',
  '流汗R',
  '浅肤色R',
  '海豚R',
  '清单R',
  '渔夫帽R',
  '满R',
  '满月R',
  '火R',
  '炸弹R',
  '点赞R',
  '烟花R',
  '爆炸R',
  '爱心R',
  '猪头R',
  '玫瑰R',
  '生日蛋糕R',
  '生气R',
  '电影R',
  '登山鞋R',
  '皱眉R',
  '看R',
  '睡觉R',
  '石化R',
  '礼物R',
  '禁R',
  '福R',
  '私信R',
  '种草R',
  '笑哭R',
  '紫色心形R',
  '红书R',
  '红包R',
  '红色心形R',
  '红薯R',
  '绿色心形R',
  '老虎R',
  '耶R',
  '背包R',
  '自拍R',
  '色色R',
  '草莓R',
  '萌萌哒R',
  '营地车R',
  '葡萄R',
  '蓝色心形R',
  '薯券R',
  '请友好R',
  '请文明R',
  '购物车R',
  '赞R',
  '蹲R',
  '辣椒R',
  '郁金香R',
  '鄙视R',
  '钱袋R',
  '长板R',
  '陆冲板R',
  '集美R',
  '零R',
  '露营R',
  '露营灯R',
  '鞭炮R',
  '风镜R',
  '飞吻R',
  '飞机R',
  '飞盘R',
  '马甲R',
  '骑行服R',
  '黄瓜R',
  '黄色心形R',
  '黄金薯R',
  '黑薯问号R',
  '鼓掌R',
].reduce((obj, cur) => (obj[cur] = cur) && obj, {});

const kuaishouEmojiNameMapping = [
  '666',
  '奸笑',
  'treacherous',
  '捂脸',
  '捂臉',
  'facepalm',
  '龇牙',
  '齜牙',
  'grin',
  '哼',
  'snort',
  '哦',
  'oh',
  '微笑',
  'smile',
  '老铁',
  '老鐵',
  'bro',
  '双鸡',
  '雙雞',
  'double',
  '调皮',
  '調皮',
  'naughty',
  '呆住',
  'stunned',
  '星星眼',
  'starryeyed',
  '爱心',
  '愛心',
  'heart',
  '疑问',
  '疑問',
  'curious',
  '生气',
  '生氣',
  'angry',
  '难过',
  '難過',
  'sad',
  '撇嘴',
  'pout',
  '惊讶',
  '驚訝',
  'surprised',
  '羞涩',
  '羞澀',
  'shy',
  '色',
  'flirting',
  '汗',
  'sweaty',
  '呕吐',
  '嘔吐',
  'vomit',
  '老司机',
  '老司機',
  'sophisticated',
  '头盔',
  '頭盔',
  'helmet',
  '酷',
  'cool',
  '笑哭',
  'laughcry',
  '愉快',
  'happy',
  '委屈',
  'wronged',
  '鄙视',
  '鄙視',
  'despise',
  '白眼',
  'disdain',
  '安排',
  'arranged',
  '点点关注',
  '點點關注',
  'follow',
  '鼓掌',
  'clap',
  '抱抱',
  'hug',
  '哈欠',
  'yawn',
  '骂你',
  '罵你',
  'curse',
  '大哭',
  'cry',
  '闭嘴',
  '閉嘴',
  'shutup',
  '惊恐',
  '驚恐',
  'scared',
  '红脸蛋',
  '紅臉蛋',
  'redface',
  '亲亲',
  '親親',
  'kiss',
  '冷汗',
  'awkward',
  '晕',
  '暈',
  'dizzy',
  '皇冠',
  'crown',
  '火',
  'fire',
  '坏笑',
  '壞笑',
  'snicker',
  '爆炸',
  'explosion',
  '大便',
  'shit',
  '可怜',
  '可憐',
  'poor',
  '抠鼻',
  '摳鼻',
  'dignose',
  '再见',
  '再見',
  'bye',
  '摄像机',
  '攝像機',
  'camera',
  '赞',
  '讚',
  'like',
  '平底锅',
  '平底鍋',
  'pan',
  '囧',
  'oops',
  '大哥',
  'boss',
  '玫瑰',
  'rose',
  '抓狂',
  'crazy',
  '嘘',
  'quiet',
  '快哭了',
  'almostcry',
  '骷髅',
  '骷髏',
  'skeleton',
  '偷笑',
  'titter',
  '落泪',
  '落淚',
  'tears',
  '挑逗',
  'tease',
  '困',
  'sleepy',
  '睡觉',
  '睡覺',
  'sleep',
  '右哼哼',
  'right',
  '左哼哼',
  'left',
  '打招呼',
  'Hi',
  'nosebleed',
  '流鼻血',
  'sneak',
  '偷瞄',
  'eatmelon',
  '吃瓜',
  'what',
  '黑臉問',
  '黑脸问',
  'rotate',
  '旋轉',
  '旋转',
  'simper',
  '憨笑',
  'rainbow',
  '吐彩虹',
  'wipenose',
  '擦鼻涕',
  'dirtytalk',
  '怒言',
  'please',
  '拜託',
  '拜托',
  'fighting',
  '加油',
  'sweat',
  '暴汗',
  'wanttoeat',
  '想吃',
  'hitface',
  '打臉',
  '打脸',
  'vomitblood',
  '吐血',
  'awkard',
  '尷尬',
  '尴尬',
  'ghost',
  '出魂兒',
  '出魂儿',
  'nostrils',
  '大鼻孔',
  'boom',
  '嘣',
  'omg',
  '天啊',
  'fossil',
  '石化',
  'frown',
  '皺眉',
  '皱眉',
  'stupid',
  '裝傻',
  '装傻',
  'sour',
  '酸了',
  'shiba',
  '柴犬',
  'dogfood',
  '狗糧',
  '狗粮',
  'waiting',
  '期待',
  'redpacket',
  '紅包',
  '红包',
  'cheers',
  '乾杯',
  '干杯',
  'pray',
  '祈禱',
  '祈祷',
  'fade',
  '花謝了',
  '花谢了',
  'knee',
  '跪下',
  'hard',
  '南',
  '发',
  '發',
  'rich',
  '板砖',
  '板磚',
  'brick',
  '灯笼',
  '燈籠',
  'latern',
  '福字',
  'fortune',
  '鞭炮',
  'banger',
  '烟花',
  '煙花',
  'fireworks',
  '元宝',
  '元寶',
  'gold',
  '钱',
  '錢',
  'money',
  '气球',
  '氣球',
  'balloon',
  '庆祝',
  '慶祝',
  'celebrate',
  '礼花',
  '禮花',
  'party',
  '爱你',
  '愛你',
  'love',
  '摸头',
  '摸頭',
  'touch',
  '雾霾',
  '霧霾',
  'pollution',
  '化妆',
  '化妝',
  'makeup',
  '涂指甲',
  '塗指甲',
  'nail',
  '欢迎',
  '歡迎',
  'welcome',
  '左拳',
  'leftfist',
  '右拳',
  'rightFist',
  '我爱你',
  '我愛你',
  'loveu',
  '比心',
  'handheart',
  '肌肉',
  'muscle',
  '狮子',
  '獅子',
  'lion',
  '龙',
  '龍',
  'dragon',
  '狗',
  'dog',
  '网红猫',
  '網紅貓',
  'kitty',
  '猫',
  '貓',
  'cat',
  '老鼠',
  'mouse',
  '不看',
  'nolook',
  '不听',
  '不聽',
  'nolisten',
  '不说',
  '不說',
  'nospeak',
  '猪头',
  '豬頭',
  'pig',
  '猪鼻子',
  '豬鼻子',
  'pignose',
  '猪蹄',
  '豬蹄',
  'pigfoot',
  '羊驼',
  '羊駝',
  'alpaca',
  '麦克风',
  '麥克風',
  'microphone',
  '跳舞',
  'dance',
  '蛋糕',
  'cake',
  '口红',
  '口紅',
  'lipstick',
  '水枪',
  '水槍',
  'watergun',
  '空投',
  'airdrop',
  '手柄',
  'gamepad',
  '坑',
  'hole',
  '八倍镜',
  '八倍鏡',
  'mirror',
  '网红',
  '網紅',
  'celebrity',
  '优秀',
  '優秀',
  'good',
  '减1',
  '減1',
  'minus',
  '必胜',
  '必勝',
  'win',
  '戴口罩',
  'N95',
  '勤洗手',
  'washhands',
  '心心',
  'thanks',
  '哭笑',
  'cryLaugh',
  '点赞',
  '點贊',
  'nice',
  'knife',
  '菜刀',
  '扎心',
  'prickekheart',
  '拍一拍',
  'pat',
];

export const emojiNameMapping = {
  ...douyinEmojiNameMapping,
  ...xiaohongshuEmojiNameMapping,
  ...kuaishouEmojiNameMapping,
};
