import { dict } from '@/hooks/useChangeLocale';
import { DATE_FORMAT_STR } from '@/utils/dateUtil';
import { Radio } from '@antd';
import dayjs from 'dayjs';
const today = dayjs();
const endDay = today.subtract(1, 'day'); //T+1
const before7Day = endDay.subtract(7, 'day');
const before15Day = endDay.subtract(15, 'day');
const before30Day = endDay.subtract(30, 'day');
const endDayStr = endDay.format(DATE_FORMAT_STR);

export const dateRangePickValues = [
  [[before7Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 7 })],
  [[before15Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 15 })],
  [[before30Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 30 })],
  [[null, null], dict('CUMULATIVE')],
];

type RecentDatePickBtnType = {
  value?: string[];
  hasBorder?: boolean;
  showTotal?: boolean;
  onChange?: (val: string[]) => void;
};
const RecentDateBtnPick: React.FC<RecentDatePickBtnType> = ({
  value,
  onChange,
  hasBorder = false,
  showTotal = true,
}) => {
  return (
    <Radio.Group
      value={value ? value.join('~') : null}
      className={hasBorder ? undefined : 'radio-button-no-border'}
      size="small"
      onChange={(e) => {
        onChange?.(e.target.value.split('~'));
      }}
    >
      {dateRangePickValues
        .filter(([key]) => (showTotal ? true : key[0]))
        .map(([key, label], index) => (
          <Radio.Button key={index} value={(key as []).join('~')}>
            <span style={{ margin: '0 5px' }}>{label}</span>
          </Radio.Button>
        ))}
    </Radio.Group>
  );
};

export default RecentDateBtnPick;
