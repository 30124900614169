import { useAppContext } from '@/contexts/AppContext';
import { Radio } from '@antd';
import type { RadioProps } from '@antd';
import { useCreation } from 'ahooks';
import { isEmpty } from 'lodash';

type EnumRadioProps = {
  type: string;
  valueKey?: 'value' | 'name';
  excludeValues?: Key[];
} & RadioProps;

export const EnumRadio = ({ type, value, valueKey = 'value', excludeValues, ...props }: EnumRadioProps) => {
  const { getEnum } = useAppContext();
  const options = useCreation(() => {
    return getEnum(type)
      .map((v) => ({
        label: v.displayName,
        value: v[valueKey],
      }))
      .filter((v) => (isEmpty(excludeValues) ? true : !excludeValues?.includes(v.value)));
  }, [type, valueKey]);

  return <Radio.Group value={value} options={options} {...props} />;
};

export default EnumRadio;
