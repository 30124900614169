import { Empty } from '@antd';
import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
// Import charts, all with Chart suffix
import { BarChart, LineChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import { useRef } from 'react';
import './index.less';
import defaultTheme from './theme';

export interface ChartCoreProps extends EChartsReactProps {
  empty?: boolean;
}

// Register the required components
echarts.use([LineChart, TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

export function ChartCore({ empty, onChartReady, ...props }: ChartCoreProps) {
  const echartRef = useRef(null);
  const onChartReadyCallback =
    onChartReady ||
    ((instance) => {
      setTimeout(() => {
        instance.resize();
      });
    });
  return empty ? (
    <div {...props}>
      <Empty style={{ paddingTop: 40 }} />
    </div>
  ) : (
    <ReactECharts
      ref={echartRef}
      theme={defaultTheme}
      style={{ width: '100%', height: '100%' }}
      onChartReady={onChartReadyCallback}
      {...props}
    />
  );
}

export default ChartCore;
