//授权成功展示页面
import { dict } from '@/hooks/useChangeLocale';
import { Result } from '@antd';
import { useMount } from 'ahooks';
export default () => {
  useMount(() => {
    const querystring = location.href;
    window.opener.postMessage(querystring, '*');
    window.close();
  });
  return (
    <div className="successData">
      <div className="centerData">
        <Result status="success" title={dict('ACCOUNT_STATUS_SUCCESS')} />
      </div>
    </div>
  );
};
