import { dict } from '@/hooks/useChangeLocale';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from '@antd';
import type { InputProps } from '@antd';
import { useDebounceFn } from 'ahooks';
import { get, trim } from 'lodash';

export const SearchInput = (props: InputProps & { onKeywordChange: (text: string) => void }) => {
  const { onKeywordChange, ...restProps } = props;

  const { run: searcInputChange } = useDebounceFn(onKeywordChange, {
    wait: 300,
  });

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder={dict('ACTION_SEARCH')}
      onInput={(e) => {
        e.persist();
        searcInputChange(trim(get(e.target, 'value')));
      }}
      {...restProps}
    />
  );
};
