import { Info } from '@/icons';
import { Descriptions, Spin, Tooltip } from '@antd';
import { isArray, isEmpty, isNil } from 'lodash-es';
import React, { ReactNode } from 'react';
import style from './index.less';

export type StatisticItem = {
  title: string | ReactNode;
  tip?: string;
  value?: Key;
  suffix?: string;
  mValues?: {
    value: Key;
    suffix?: string;
  }[];
  otherLayoutDirection?: 'horizontal' | 'vertical';
  other?: StatisticItem[];
};

type StatisticCardPanelProps = {
  items: StatisticItem[];
  columns?: number;
  loading?: boolean;
  title?: React.ReactNode;
};

const checkValue = (value) => {
  return !isNil(value) && !['--', '-'].includes(value);
};

const formatValue = (value) => {
  return checkValue(value) ? value : '-';
};

export const StatisticCardPanel: React.FC<StatisticCardPanelProps> = ({ items, loading = false, columns, title }) => {
  return (
    <Spin spinning={loading}>
      <Descriptions
        title={title}
        className={style['statisic-data-card']}
        layout="vertical"
        column={columns || items.length}
      >
        {items.map(({ title, value, suffix, mValues, tip, other, otherLayoutDirection }, index) => (
          <Descriptions.Item
            label={
              <div style={{ display: 'flex' }}>
                {title}
                {tip ? (
                  <Tooltip title={tip}>
                    <Info />
                  </Tooltip>
                ) : undefined}
              </div>
            }
            key={index}
          >
            <div className={style.content}>
              <div>
                {isArray(mValues) ? (
                  mValues
                    .filter((m) => !isNil(m.value))
                    .map((m, index) => (
                      <React.Fragment key={index}>
                        <span className={style['statisic-data-card-value']}>{formatValue(m.value)}</span>
                        {m.suffix && checkValue(m.value) ? (
                          <span className={style['statisic-data-card-unit']}>{m.suffix}</span>
                        ) : null}
                      </React.Fragment>
                    ))
                ) : (
                  <>
                    <span className={style['statisic-data-card-value']}>{formatValue(value)}</span>
                    {suffix && checkValue(value) ? (
                      <span className={style['statisic-data-card-unit']}>{suffix}</span>
                    ) : null}
                  </>
                )}
              </div>
              {isEmpty(other) ? undefined : (
                <div
                  className={style['statisic-data-card-other']}
                  style={
                    otherLayoutDirection == 'horizontal'
                      ? { display: 'flex', justifyContent: 'space-between' }
                      : undefined
                  }
                >
                  {other?.map((item, index) => {
                    return (
                      <div key={index} style={{ minWidth: 100 }}>
                        {item?.title}
                        {tip ? (
                          <Tooltip title={tip}>
                            <Info />
                          </Tooltip>
                        ) : undefined}
                        {otherLayoutDirection == 'horizontal' ? <br /> : ':'} {formatValue(item.value)}
                        {item.suffix && checkValue(item.value) ? item.suffix : ''}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Spin>
  );
};
