import { AuthorizeStatusBadgeMap, MEDIA_KEY_ENUM } from '@/constants/const';
import { AuthorizeStatusEnum } from '@/constants/enum';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getAccountList } from '@/services/account';
import type { SimpleAccountData } from '@/services/account/type';
import { getOrganizationList } from '@/services/organization';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { Badge, TreeSelect, TreeSelectProps, Typography } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { get, isArray, isEmpty, isNumber } from 'lodash';
import React from 'react';

export const OrgAccountTreeSelect: React.FC<
  TreeSelectProps & { filterMediaIds?: MEDIA_KEY_ENUM | MEDIA_KEY_ENUM[] }
> = ({ filterMediaIds, value, ...props }) => {
  const { user, getEnum } = useAppContext();
  const { data, loading } = useRequest(() => Promise.all([getOrganizationList(user?.bid), getAccountList(user?.bid)]), {
    refreshDeps: [user],
  });

  const [organizations, accounts] = data || [];

  const treeData = useCreation(() => {
    if (Validator.isNilEmpty(organizations) || Validator.isNilEmpty(accounts)) return [];

    let filterAccounts = accounts as SimpleAccountData[];
    if (!Validator.isNilEmpty(filterMediaIds) || filterMediaIds != undefined) {
      //需过滤的平台
      if (!isArray(filterMediaIds)) {
        filterMediaIds = [filterMediaIds as unknown as MEDIA_KEY_ENUM];
      }
      //过滤账号
      filterAccounts = filterAccounts
        ?.map((orgGroup) => {
          orgGroup.accountDataList = orgGroup?.accountDataList.filter(({ mediaId }) => {
            return (filterMediaIds as Array<MEDIA_KEY_ENUM>).includes(mediaId);
          });
          return orgGroup;
        })
        .filter((v) => !isEmpty(v?.accountDataList));
    }

    const organizationMap = organizations?.reduce((map, cur) => (map[cur.id] = cur) && map, {}) || {};
    //标记组织是否有账号
    const remarkOrgHasAccount = (orgId) => {
      const curOrg = organizationMap[orgId];
      if (curOrg && !curOrg._isAccountParent) {
        curOrg._isAccountParent = true;
        remarkOrgHasAccount(curOrg.parentId);
      }
    };

    filterAccounts.forEach((accountGroup) => remarkOrgHasAccount(accountGroup.orgId));

    const cache = {}; //结点缓存
    const roots: AnyObject[] = [];

    //处理节点
    organizations
      ?.filter((org) => org && organizationMap[org.id]._isAccountParent)
      .sort((v1, v2) => v1.type - v2.type)
      .forEach((org) => {
        const label = org.name + (org.code ? `  (${org.code})` : '');
        const curOrg = {
          ...org,
          isLeaf: false,
          value: `ORG_${org.id}`,
          title: (
            <Typography.Text title={label} style={{ maxWidth: 180 }} ellipsis>
              {label}
            </Typography.Text>
          ),
          // checkable: false,
          selectable: false,
        };
        cache[org.id] = curOrg;
        if (org.parentId) {
          //当前节点添加父结点
          if (cache[org.parentId]) {
            cache[org.parentId].children = cache[org.parentId].children || [];
            cache[org.parentId].children.push(curOrg);
          } else {
            cache[org.parentId] = get(organizationMap, org.parentId);
          }
        } else {
          roots.push(curOrg); //根结点
        }
      });

    //处理账号
    filterAccounts.forEach((accountGroup) => {
      const cacheOrg = cache[accountGroup.orgId];
      if (!cacheOrg) return;
      cacheOrg.children = cacheOrg.children || [];

      accountGroup?.accountDataList.forEach((account) => {
        const { mediaId, loginAuthStatus, creatorLoginAuthStatus, name } = account;
        const status = mediaId == MEDIA_KEY_ENUM.DOUYIN ? creatorLoginAuthStatus : loginAuthStatus;
        const checkable = status == AuthorizeStatusEnum.ALREAD;
        const curAccountNode = {
          ...account,
          value: account.id,
          checkable,
          title: (
            <Typography.Text
              title={Format.toArrLabel(getEnum('AccountAuthStatusEnum'), status, 'value', 'displayName')}
              ellipsis
            >
              <Badge status={AuthorizeStatusBadgeMap[status]} text={name} />
            </Typography.Text>
          ),
          selectable: false,
        };
        cacheOrg.children.push(curAccountNode);
      });
    });

    return roots;
  }, [organizations, accounts, filterMediaIds]);

  return (
    <TreeSelect
      showSearch
      loading={loading}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ORGANIZATIONAL_ACCOUNT') })}
      treeLine
      allowClear
      treeData={treeData}
      value={isEmpty(treeData) ? undefined : value || undefined}
      filterTreeNode={(inputV, node) => {
        if (!inputV) return true;
        const v = inputV.toLowerCase();
        return node.name.toLowerCase().includes(v) || (node.code || '').toLowerCase().includes(v);
      }}
      {...props}
      onChange={(values, labelList, extra) => {
        props.onChange?.(values?.filter(isNumber), labelList, extra);
      }}
    />
  );
};
