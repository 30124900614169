import { dict } from '@/hooks/useChangeLocale';
import { Form, Modal, ModalProps } from '@antd';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Function2, Function3 } from 'lodash';
import React, { useEffect, useState } from 'react';
import TagCascader from '../Form/Field/select/TagCascader';
import { defaultFormItemConfig } from '@/constants/const';

type AddTagModalProps = {
  onTagSelect?: Function2<Key[][], Key, void>;
} & ModalProps;

export const useAddTagModal = (): [React.FC<AddTagModalProps>, Function3<Key[][], Key[][], Key, void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [tagIds, setTagIds] = useState<Key[][]>([]);
  const [autoTagIds, setAutoTagIds] = useState<Key[][]>([]);
  const [targetId, setTargetId] = useState<Key>();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ tagIds });
  }, [tagIds, form]);

  const AddTagModal = useMemoizedFn(({ children, onTagSelect, ...params }: AddTagModalProps) => {
    return (
      <Modal
        title={dict('ACTION_ADD_NAME', { name: dict('TAG') })} //添加标签
        open={isModalOpen}
        onCancel={() => {
          hideModal();
          form.resetFields();
        }}
        onOk={async () => {
          try {
            const { tagIds } = await form.validateFields();
            onTagSelect?.([...tagIds, ...(autoTagIds || [])], targetId as Key);
            hideModal();
          } catch (error) {}
        }}
        width={600}
        {...params}
      >
        <Form {...defaultFormItemConfig} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={form}>
          <Form.Item
            name="tagIds"
            label={dict('SELECT_NAME', { name: dict('TAG') })}
            rules={[{ required: true, message: dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('TAG') }) }]}
          >
            <TagCascader
              style={{ width: '100%' }}
              manual
              multiple
              maxTagCount={undefined}
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('TAG') })}
              triggerSingleTagCheck
              onChange={(tagIds) => setTagIds(tagIds)}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  });

  const handleRecord = useMemoizedFn((menualTagIds: Key[][], autoTagIds: Key[][], targetId: Key) => {
    setTagIds(menualTagIds);
    setAutoTagIds(autoTagIds);
    setTargetId(targetId);
    showModal();
  });

  return [AddTagModal, handleRecord];
};
