import { useAppContext } from '@/contexts/AppContext';
import { getTagList } from '@/services/tag/indext';
import { Cascader, CascaderProps } from '@antd';
import { useRequest } from 'ahooks';
import { Function1, isEmpty } from 'lodash';

type TagSelect = {
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: Function1<any[], void>;
  value?: any;
  manual?: boolean; // 是否手动标签
  triggerSingleTagCheck?: boolean;
  multiple?: boolean; //是否多选
  maxTagCount?: number | 'responsive' | undefined;
};

const displayRender = (labels: string[]) => labels.join('-');

const TagCascader = ({ manual, triggerSingleTagCheck, ...props }: TagSelect) => {
  const { user } = useAppContext();

  const { data, loading } = useRequest(
    async () => {
      const list = await getTagList({ bid: user?.bid });
      const singleTagIds: Key[] = [];
      const options: CascaderProps['options'] = list
        .filter((v) => (manual ? v.type == 'manual' : true))
        .map((v) => {
          if (v.valType == 'single') singleTagIds.push(v.id);

          return {
            label: v.name,
            value: v.id,
            children: v.ruleConfig.map((t) => {
              return {
                label: t.valueName,
                value: t.tagValueId,
              };
            }),
          };
        });
      return [options, singleTagIds];
    },
    { refreshDeps: [user?.bid, manual] },
  );

  const [tagOptions = [], singleTagIds = []] = data || [];

  return (
    <Cascader
      maxTagCount="responsive"
      loading={loading}
      // expandTrigger="hover"
      optionRender={(option) => {
        const { label } = option;
        return (
          <div title={label?.toString()} style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {label}
          </div>
        );
      }}
      displayRender={displayRender}
      options={tagOptions as CascaderProps['options']}
      showCheckedStrategy={Cascader.SHOW_CHILD}
      {...props}
      onChange={(values) => {
        let newValues: AnyObject[] = values;
        if (triggerSingleTagCheck && props.multiple) {
          const singleValues = {};
          const multipleValues = values?.filter((value) => {
            const [v1, v2] = value as unknown as [Key, Key];
            if (!isEmpty(singleTagIds) && singleTagIds.includes(v1 as AnyObject)) {
              singleValues[v1] = v2;
              return false;
            }
            return true;
          }) as [];
          newValues = [...multipleValues, ...Object.entries(singleValues).map(([v1, v2]) => [Number(v1), v2])];
        }
        props.onChange?.(newValues);
      }}
    />
  );
};

export default TagCascader;
