import { useCreation } from 'ahooks';
import { isArray } from 'lodash';
import React, { CSSProperties } from 'react';
import HighlightText from '../HighlightText';
import style from './index.less';
import { emojiNameMapping } from './mapping';

type EmojiImgText = {
  content: string;
  keywords?: string | string[]; //高亮的关键词
  searchWord?: string; //搜索词
  style?: CSSProperties | undefined;
};

const regex = /\[(.*?)\]/gm;
const filenamePrefix = '-----filename=';
export const EmojiImgText: React.FC<EmojiImgText> = ({ content, keywords, searchWord, ...prop }) => {
  const keywordArr = useCreation(() => {
    const regexStrs = isArray(keywords) ? keywords : [keywords];
    return regexStrs
      .filter((v) => v)
      .filter((v) => {
        try {
          new RegExp(v as string);
          return true;
        } catch (error) {
          return false;
        }
      }) as string[];
  }, [JSON.stringify(keywords)]);

  const parts = (content || '')
    .replace(regex, (match: string, origin: string) => {
      const fileName = emojiNameMapping[origin];
      if (fileName) {
        return `[${filenamePrefix + fileName}]`;
      }
      return `[${match}]`;
    })
    .split(regex);

  return (
    <span className={style['emoji-text']} {...prop}>
      {parts
        .filter((part) => part.trim())
        .map((part, index) => {
          if (part.startsWith(filenamePrefix)) {
            return <img key={index} src={`${BASE_PATH}/emoji/${part.replace(filenamePrefix, '')}.png`} />;
          }
          return (
            <React.Fragment key={index}>
              {keywords ? <HighlightText keywordText={keywordArr} text={part} searchText={searchWord} /> : part}
            </React.Fragment>
          );
        })}
    </span>
  );
};
