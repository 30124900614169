import { dict } from '@/hooks/useChangeLocale';
import { QueryFilter } from '@ant-design/pro-components';
import type { QueryFilterProps } from '@ant-design/pro-components';
import { useMount } from 'ahooks';
import { Form } from '@antd';

export function TableQueryFilter<T = Record<string, AnyObject>>({
  children,
  initialValues = {},
  resetValues = {},
  form,
  ...rest
}: QueryFilterProps<T> & { resetValues: AnyObject }) {
  const [formInstance] = Form.useForm(form);

  useMount(() => {
    formInstance?.setFieldsValue(initialValues);
  });

  return (
    <QueryFilter
      form={formInstance}
      searchText={dict('ACTION_SEARCH')}
      {...rest}
      onReset={() => {
        formInstance?.setFieldsValue(resetValues);
      }}
    >
      {children}
    </QueryFilter>
  );
}
