import { useAppContext } from '@/contexts/AppContext';
import { Select } from '@antd';
import type { SelectProps } from '@antd';
import { useCreation } from 'ahooks';

type KeyTypes = 'value' | 'displayName' | 'name';

type EnumSelectProps = {
  type: string;
  labelKey?: KeyTypes;
  valueKey?: KeyTypes;
} & SelectProps;

export const EnumSelect = ({
  type,
  labelKey = 'displayName',
  valueKey = 'value',
  value,
  ...props
}: EnumSelectProps) => {
  const { getEnum } = useAppContext();
  const options = useCreation(() => {
    return getEnum(type).map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  }, [type]);

  return (
    <Select value={Array.isArray(value) ? value : Number(value) || value || undefined} options={options} {...props} />
  );
};

export default EnumSelect;
