import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PageContainer, PageContainerProps, ProCard } from '@ant-design/pro-components';
import type { ProCardProps } from '@ant-design/pro-components';
import { Alert, Space } from '@antd';
import React, { CSSProperties, ReactNode, useLayoutEffect, useRef } from 'react';

import style from './index.less';

export const Container = function ({ children, ...props }: PageContainerProps) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const data = ref.current?.getBoundingClientRect() || { top: 0 };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (ref.current) ref.current.parentElement!.style.height = `calc(100vh - ${data?.top - 8}px)`;
  });

  return (
    <PageContainer className={style['page-container']} header={{ style: { display: 'none' } }}>
      <div className={style['page-inner-content-wrap']} ref={ref}>
        {children}
      </div>
    </PageContainer>
  );
};

export const HeadCard = function ({ title, children, ...rest }: ProCardProps) {
  return (
    <ProCard
      headStyle={{ backgroundColor: 'rgba(0,0,0,0.01)', paddingBlockEnd: 8, paddingBlock: 9 }}
      headerBordered
      title={title}
      {...rest}
    >
      {children}
    </ProCard>
  );
};

export const FormCard = function ({ title, children, subTitle, ...rest }: ProCardProps) {
  return (
    <ProCard className={style['form-card']} headerBordered title={title} {...rest}>
      <>
        {subTitle ? (
          <Alert
            message={subTitle}
            type="info"
            icon={<ExclamationCircleOutlined />}
            closable={false}
            style={{ border: 0, backgroundColor: 'transparent', marginTop: -15, marginBottom: 5, marginLeft: -25 }}
            showIcon
          />
        ) : null}
        {children}
      </>
    </ProCard>
  );
};

type LineBarProps = {
  title: string;
  style?: CSSProperties;
  extra?: ReactNode;
};
export const LineBar: React.FC<LineBarProps> = ({ title, extra, ...props }) => {
  return (
    <div className={style['line-bar']} {...props}>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{title}</span>
        {extra}
      </Space>
    </div>
  );
};

/**
 * 空白行
 */
export const Blank = () => <div style={{ height: 10, backgroundColor: 'transparent' }} />;

export default Container;
