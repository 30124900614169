import { Space as AntSpace, SpaceProps } from '@antd/Antd';

export const Space = ({ children, ...props }: SpaceProps) => {
  return (
    <AntSpace size={12} {...props}>
      {children}
    </AntSpace>
  );
};

export default Space;
