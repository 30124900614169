import { App, Form, Input, Modal, type ModalProps } from '@antd';
import { Function1, Function3 } from 'lodash';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { MEDIA_KEY_ENUM, defaultFormItemConfig } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { useState } from 'react';
import { useAppContext } from '@/contexts/AppContext';
import { creatTopCase } from '@/services/top_case';
import { DateUtils } from '@/utils/dateUtil';

type CollectMaterialCaseModalProps = { onCollectedOk: Function1<number, void> } & ModalProps;
export const useCollectMaterialModal = (): [
  React.FC<CollectMaterialCaseModalProps>,
  boolean,
  Function3<Key, 'live' | 'item', MEDIA_KEY_ENUM, void>,
] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const CollectMaterialModal = useMemoizedFn((props: CollectMaterialCaseModalProps) => {
    const { message } = App.useApp();
    const { user } = useAppContext();

    return (
      <Modal
        title={dict('JOIN_EXCELLENT_CASE')}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        {...props}
        onOk={async () => {
          try {
            setLoading(true);
            const params = await form.validateFields();
            const res = await creatTopCase({
              bid: user?.bid,
              tenantId: user.tenantId,
              addDate: DateUtils.format(undefined),
              ...params,
            });

            if (res) {
              message.success(dict('OPERATION_SUCCESS'));
              props.onCollectedOk(params.id);
              hideModal();
            }
          } catch (error) {}
          setLoading(false);
        }}
        width={600}
      >
        <Form {...defaultFormItemConfig} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={form}>
          <Form.Item hidden name="bizId">
            <Input />
          </Form.Item>
          <Form.Item hidden name="type">
            <Input />
          </Form.Item>
          <Form.Item hidden name="mediaId">
            <Input />
          </Form.Item>

          {/* 推荐理由 */}
          <Form.Item name="reason" label={dict('RECOMADATION_REASION')} >
            <Input.TextArea
              autoSize={{ minRows: 4 }}
              placeholder={dict('COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP', { count: 2000 })}
              maxLength={2000}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  });

  return [
    CollectMaterialModal,
    loading,
    (bizId, type, mediaId) => {
      form?.setFieldsValue({ bizId, type, mediaId, reason: '' });
      showModal();
    },
  ];
};
